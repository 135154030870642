import React from 'react'

import './Footer.scss'
interface FooterProps {
    className?: string
}

const Footer: React.FC<FooterProps> = ({ className = '' }) => {
    return (
        <footer className={`footer ${className}`}>
            <span className='footer-text'>
                shouldcosting GmbH {new Date().getFullYear()} &copy;
            </span>
        </footer>
    )
}

export default Footer
