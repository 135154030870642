import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'

import './Layout.scss'

interface LayoutActions {}

export const LayoutContext: React.Context<LayoutActions | null> =
    React.createContext(null as LayoutActions | null)

interface LayoutProviderProps {
    children: React.ReactNode
}

export const LayoutProvider: React.FC<LayoutProviderProps> = ({ children }) => (
    <LayoutContext.Provider value={{}}>
        <div className='layout-container'>
            <Header className='layout-header' />
            <main className='layout-main'>{children}</main>
            <Footer className='layout-footer' />
        </div>
    </LayoutContext.Provider>
)
