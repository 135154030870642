import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import ILogger from './logging/ILogger'
import { LoggerProvider } from './logging/LoggerProvider'

const baseUrl =
    document.getElementsByTagName('base')[0].getAttribute('href') ?? '/'
const rootElement = document.getElementById('root')

if (!rootElement) {
    throw new Error('No root element found')
}

const root = createRoot(rootElement)

let logger: ILogger

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    logger = console
} else {
    console.warn('Logger not implemented')
    //empty logger to avoid errors
    logger = {
        log: () => {},
        info: () => {},
        warn: () => {},
        error: () => {},
    }
}

root.render(
    <BrowserRouter basename={baseUrl}>
        <LoggerProvider logger={logger}>
            <App />
        </LoggerProvider>
    </BrowserRouter>
)
