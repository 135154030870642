import React from 'react'
import { useLogger } from '../hooks/Logger'

export interface Services {}

export const ServiceContext: React.Context<Services | null> =
    React.createContext(null as Services | null)

interface ServiceProviderProps {
    children: React.ReactNode
}

export const ServiceProvider: React.FC<ServiceProviderProps> = ({
    children,
}) => {
    const serviceLogger = useLogger()

    const services: Services = {}

    return (
        <ServiceContext.Provider value={services}>
            {children}
        </ServiceContext.Provider>
    )
}
