import React from "react";
import { LoggerContext } from "../logging/LoggerProvider";

export const useLogger = () => {
    const ctx = React.useContext(LoggerContext);
    if (ctx === null) {
        throw new Error("useLogger must be used within a LoggerProvider");
    }
    return ctx;
};
