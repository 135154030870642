import React from "react";
import ILogger from "./ILogger";

export const LoggerContext: React.Context<ILogger | null> = React.createContext(
    null as ILogger | null
);

interface LoggerProviderProps {
    logger: ILogger;
    children: React.ReactNode;
}

export const LoggerProvider: React.FC<LoggerProviderProps> = ({
    logger,
    children,
}) => {
    return (
        <LoggerContext.Provider value={logger}>
            {children}
        </LoggerContext.Provider>
    );
};
