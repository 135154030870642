import React from 'react'
//@ts-ignore
import logo from '../images/shc_logo_mit_Schriftzug.png'

import './Header.scss'
import { useTranslation } from 'react-i18next'

interface HeaderProps {
    className?: string
}

const Header: React.FC<HeaderProps> = ({ className = '' }) => {
    const { t } = useTranslation()

    return (
        <header className={`header ${className}`}>
            <h1>{t('header.title')}</h1>
            <img
                className='logo'
                src={logo}
                onClick={e => {
                    e.preventDefault()
                    if (window.open) {
                        //@ts-ignore
                        window
                            .open('https://www.shouldcosting.com/', '_blank')
                            .focus()
                    }
                }}
            />
        </header>
    )
}

export default Header
