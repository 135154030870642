const LocalRoutes = {
    INDEX: '/',
}

export const LocalRoutesFactory = {
    getReportRoute(reportId: number): string {
        return `/reports/${reportId}`
    },
}

export default LocalRoutes
