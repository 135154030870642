import React from 'react'
import { useTranslation } from 'react-i18next'

import './Report.scss'

import preview from '../images/preview.png'

const Report = () => {
    const { t } = useTranslation()

    return (
        <div className='report-page'>
            <img
                src={preview}
                alt='preview'
                style={{
                    height: '100%',
                    maxWidth: '100%',
                    objectFit: 'contain',
                }}
            />
        </div>
    )
}

export default Report
