import React, { Component } from 'react'
import { Route, Routes } from 'react-router-dom'
import { useLogger } from './hooks/Logger'
import { ServiceProvider } from './services/ServiceProvider'
import { LayoutProvider } from './containers/Layout'
import LocalRoutes from './LocalRoutes'
import Report from './containers/Report'
import './i18n/config'
import './index.scss'

const AppLayout = () => (
    <Routes>
        <Route path={LocalRoutes.INDEX} element={<Report />} />
        <Route path='*' element={<p>Theres nothing here: 404!</p>} />
    </Routes>
)

const App = () => {
    const logger = useLogger()

    return (
        <ServiceProvider>
            <LayoutProvider>
                <AppLayout />
            </LayoutProvider>
        </ServiceProvider>
    )
}

export default App
